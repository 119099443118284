// extracted by mini-css-extract-plugin
export var schoolBorderImage = "school-module--school-border-image--dsgs0";
export var schoolCardHr = "school-module--school-card-hr--uRvOZ";
export var schoolContainer = "school-module--school-container--0XHmX";
export var schoolCourseAppIcon = "school-module--school-course-app-icon--UffgI";
export var schoolCourseApps = "school-module--school-course-apps--IvOTy";
export var schoolCourseAppsNotice = "school-module--school-course-apps-notice--9814P";
export var schoolCourseCard = "school-module--school-course-card--i-j7G";
export var schoolCourseCardHeader = "school-module--school-course-card-header--ucGVg";
export var schoolCourseImage = "school-module--school-course-image--cwBqq";
export var schoolCourseImageWrapper = "school-module--school-course-image-wrapper--nq2Ub";
export var schoolCoursePoint = "school-module--school-course-point--5wFGK";
export var schoolCoursePointBody = "school-module--school-course-point-body--CptrI";
export var schoolCoursePointBodyIcon = "school-module--school-course-point-body-icon--rwyd9";
export var schoolCoursePointBodyIconWrapper = "school-module--school-course-point-body-icon-wrapper--aPQ5F";
export var schoolCoursePointBodyRow = "school-module--school-course-point-body-row---v6b3";
export var schoolCoursePointBodyText = "school-module--school-course-point-body-text--eCYJz";
export var schoolCoursePointCushion = "school-module--school-course-point-cushion--sK7xj";
export var schoolCoursePointHeader = "school-module--school-course-point-header--MQE-B";
export var schoolCoursePointText = "school-module--school-course-point-text--y8SUe";
export var schoolCoursePopup = "school-module--school-course-popup--DkRT1";
export var schoolCoursePopupCushion = "school-module--school-course-popup-cushion--XSmVf";
export var schoolCoursePopupText = "school-module--school-course-popup-text--CPg3Z";
export var schoolCourseTag = "school-module--school-course-tag--EPPos";
export var schoolCourseTagIcon = "school-module--school-course-tag-icon--p9TXe";
export var schoolCourseTagText = "school-module--school-course-tag-text--jcJcg";
export var schoolCourseTagsGrid = "school-module--school-course-tags-grid--eqtYt";
export var schoolCourseTitle = "school-module--school-course-title--VyM4W";
export var schoolCourseWrapper = "school-module--school-course-wrapper--LHq0T";
export var schoolDescriptionImage = "school-module--school-description-image--BIHVV";
export var schoolDescriptionLower = "school-module--school-description-lower--xq8jS";
export var schoolDescriptionNotice = "school-module--schoolDescriptionNotice--L4sPf";
export var schoolDescriptionRow = "school-module--school-description-row--rrqq0";
export var schoolDescriptionText = "school-module--school-description-text--J0hdw";
export var schoolDescriptionTitle = "school-module--school-description-title--zUQAu";
export var schoolDescriptionUpper = "school-module--school-description-upper--JOS1s";
export var schoolDescriptionWrapper = "school-module--school-description-wrapper--oNVVi";
export var schoolLineButton = "school-module--school-line-button--cL0df";
export var schoolLineButtonWrapper = "school-module--school-line-button-wrapper--V5+zP";
export var schoolLineText = "school-module--school-line-text--MC1Nt";
export var schoolLineWrapper = "school-module--school-line-wrapper--MW5Ts";
export var schoolPriceBody = "school-module--school-price-body--bGmjH";
export var schoolPriceBodyNotice = "school-module--school-price-body-notice--7FIE-";
export var schoolPriceBodyText = "school-module--school-price-body-text--WQVA6";
export var schoolPriceBodyTextLarge = "school-module--school-price-body-text-large--z47B3";
export var schoolPriceBodyTitle = "school-module--school-price-body-title--pd7gg";
export var schoolPriceButton = "school-module--school-price-button--JVKQD";
export var schoolPriceButtonRow = "school-module--school-price-button-row--hHsls";
export var schoolPriceCard = "school-module--school-price-card--8Bmdq";
export var schoolPriceHr = "school-module--school-price-hr--kL45b";
export var schoolPriceLabel = "school-module--school-price-label--JGQ+I";
export var schoolPriceLabelText = "school-module--school-price-label-text--7Wkx2";
export var schoolPricePopup = "school-module--school-price-popup--NAhup";
export var schoolPricePopupCushion = "school-module--school-price-popup-cushion--67tu2";
export var schoolPricePopupText = "school-module--school-price-popup-text--ciWCw";
export var schoolPricePopupWrapper = "school-module--school-price-popup-wrapper--JVV5Q";
export var schoolPriceText = "school-module--school-price-text--+GsOh";
export var schoolSupport = "school-module--school-support--6eX-3";
export var schoolSupportInner = "school-module--school-support-inner--i7e8f";
export var schoolSupportStaff = "school-module--school-support-staff--DJZUj";
export var schoolSupportStaffButton = "school-module--school-support-staff-button--705bk";
export var schoolSupportStaffImage = "school-module--school-support-staff-image--MDjKz";
export var schoolSupportText = "school-module--school-support-text--7gmco";
export var schoolSupportTitle = "school-module--school-support-title--4Q-OD";
export var schoolTitleEn = "school-module--school-title-en--oerLy";
export var schoolTitleJp = "school-module--school-title-jp--H1Bj6";