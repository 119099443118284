import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Layout from '../components/layout'
import * as styles from '../styles/school.module.css'

const School = () => {
  const breakpoints = useBreakpoint()

  return (
    <Layout
      isHome={false}
      title="クリエイティブスクール"
      description="未経験からWebデザイン・プログラミングをマスター！マンツーマン・質問し放題なので、自分のペースで素早く技術を習得することができます。目的に応じて様々なコースをご用意しております。"
    >
      <div className={styles.schoolContainer}>
        <h1 className={styles.schoolTitleEn}>Creative School</h1>
        <h2 className={styles.schoolTitleJp}>クリエイティブスクール</h2>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-moss-top.png"
        />
        <div className={styles.schoolDescriptionWrapper}>
          <div className={styles.schoolDescriptionUpper}>
            <div className={styles.schoolDescriptionRow}>
              <h2 className={styles.schoolDescriptionTitle}>
                未経験からWebデザインをマスター！
              </h2>
              <p className={styles.schoolDescriptionText}>
                <strong>マンツーマン・質問し放題</strong>なので、自分のペースを保ちながら、<strong>独学よりも素早く</strong>技術を習得することができます。
              </p>
            </div>
            <StaticImage
              className={styles.schoolDescriptionImage}
              alt="クリエイティブスクール"
              src="../images/blobs-creative-school.png"
            />
          </div>
          <div className={styles.schoolDescriptionLower}>
            <div className={styles.schoolDescriptionRow}>
              <p className={styles.schoolDescriptionText}>
                プロのWebデザイナーを目指す方から、サクッとホームページを作りたい方まで、<strong>目的別に様々なコース</strong>をご用意しております。<br/>
                どのコースが向いているかのご相談も<strong>LINEにて無料</strong>で承っております。
              </p>
            </div>
            <p className={styles.schoolDescriptionNotice}>
              募集を締め切りました。<br />次回の募集までお待ちください。
            </p>
          </div>
          {/* <div className={styles.schoolLineButtonWrapper}>
            <a className={styles.schoolLineButton} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
              無料でLINE相談してみる
            </a>
          </div> */}
        </div>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-moss-bottom.png"
        />
        <div id="master-course" className={styles.schoolCourseWrapper}>
          <div className={styles.schoolCourseImageWrapper}>
            <StaticImage
              className={styles.schoolCourseImage}
              alt="Webデザイン・マスターコース"
              src="../images/blobs-master-course.png"
            />
          </div>
          <div className={styles.schoolCourseCard}>
            <div className={styles.schoolCourseCardHeader}>
              <h2 className={styles.schoolCourseTitle}>
                Webデザイン・{
                  breakpoints.sx || breakpoints.sm || breakpoints.md ? <br/> : null
                }マスターコース
              </h2>
              <div className={styles.schoolCoursePopup}>
                <StaticImage
                  className={styles.schoolCoursePopupCushion}
                  alt="Number"
                  src="../images/cushion-stars.png"
                />
                <p className={styles.schoolCoursePopupText}>人気<br/>No.1</p>
              </div>
            </div>
            <div className={styles.schoolCourseTagsGrid}>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="未経験から"
                  src="../images/icon-leaf.png"
                />
                <p className={styles.schoolCourseTagText}>未経験から</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="質問し放題"
                  src="../images/icon-fukidashi.png"
                />
                <p className={styles.schoolCourseTagText}>質問し放題</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="マンツーマン"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.schoolCourseTagText}>マンツーマン</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="お手頃料金"
                  src="../images/icon-money.png"
                />
                <p className={styles.schoolCourseTagText}>お手頃料金</p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="こんな人におすすめ"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>こんな人におすすめ</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <p className={styles.schoolCoursePointBodyText}>
                  <strong>Webデザイナーを目指す人、自分でホームページを運営したい人</strong>
                </p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="このコースのゴール"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>このコースのゴール</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    就職やフリーランス案件獲得に必要なポートフォリオ制作
                  </p>
                </div>
                <div className={styles.schoolCoursePointBodyRow}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    自分でデザインしたホームページをインターネット上に公開
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="習得できるスキル"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>習得できるスキル</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    Photoshop / Illustrator / Figma / HTML / CSS / Bootstrap
                  </p>
                </div>
                <div className={styles.schoolCoursePointBodyRow}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    Webデザインの基礎、各種デザインソフトの使い方、コーディング
                  </p>
                </div>
              </div>
            </div>
            <hr className={styles.schoolCardHr}/>
            <div className={styles.schoolCourseApps}>
              <StaticImage
                className={styles.schoolCourseAppIcon}
                alt="Photoshop"
                src="../images/icon-photoshop.png"
              />
              <StaticImage
                className={styles.schoolCourseAppIcon}
                alt="Photoshop"
                src="../images/icon-illustrator.png"
              />
              <StaticImage
                className={styles.schoolCourseAppIcon}
                alt="Photoshop"
                src="../images/icon-figma.png"
              />
              <StaticImage
                className={styles.schoolCourseAppIcon}
                alt="Photoshop"
                src="../images/icon-html.png"
              />
              <StaticImage
                className={styles.schoolCourseAppIcon}
                alt="Photoshop"
                src="../images/icon-css.png"
              />
              <StaticImage
                className={styles.schoolCourseAppIcon}
                style={breakpoints.sx || breakpoints.sm || breakpoints.md ? {width: "45px"} : {width: "60px"}}
                alt="Photoshop"
                src="../images/icon-bootstrap.png"
              />
            </div>
            <p className={styles.schoolCourseAppsNotice}>
              ＊Adobe社のソフト（Photoshop / Illustrator）はご自身でご契約していただきます。
            </p>
            <div className={styles.schoolPriceCard}>
              <div className={styles.schoolPricePopupWrapper}>
                <div className={styles.schoolPricePopup}>
                  <StaticImage
                    className={styles.schoolPricePopupCushion}
                    alt="マンツーマンでこの価格！"
                    src="../images/cushion-fukidashi.png"
                  />
                  <p className={styles.schoolPricePopupText}><strong>マンツーマン</strong><br/>でこの価格！</p>
                </div>
              </div>
              <div className={styles.schoolPriceLabel}>
                <span className={styles.schoolPriceLabelText}>料金プラン</span>
              </div>
              <p className={styles.schoolPriceText}>
                レッスン 全<span className={styles.schoolPriceBodyTextLarge}>20</span>回
                {breakpoints.xs || breakpoints.sm || breakpoints.md ? <br/> : " / "}
                <span style={breakpoints.xs || breakpoints.sm || breakpoints.md ? {fontSize: "13px"} : {fontSize: "16px"}}>週1〜2回 / 自己学習目安（10〜20時間/週）</span>
              </p>
              {breakpoints.xs || breakpoints.sm || breakpoints.md ? <hr className={styles.schoolPriceHr}/> : null}
              <div className={styles.schoolPriceBody}>
                <p className={styles.schoolPriceBodyTitle}>4ヶ月コース</p>
                <p className={styles.schoolPriceBodyText}>月額<span className={styles.schoolPriceBodyTextLarge}>25,000</span>円</p>
                <p className={styles.schoolPriceBodyText}>1レッスンあたり: <span className={styles.schoolPriceBodyTextLarge}>5,000</span>円</p>
              </div>
              <div className={styles.schoolPriceBody}>
                <p className={styles.schoolPriceBodyTitle}>2ヶ月コース</p>
                <p className={styles.schoolPriceBodyText}>月額<span className={styles.schoolPriceBodyTextLarge}>50,000</span>円</p>
                <p className={styles.schoolPriceBodyText}>1レッスンあたり: <span className={styles.schoolPriceBodyTextLarge}>5,000</span>円</p>
              </div>
              <p className={styles.schoolPriceBodyNotice}>
                ＊期間は1ヶ月〜最大4ヶ月まで、カスタマイズ可能です。
              </p>
              <div className={styles.schoolPriceButtonRow}>
                {/* <Link to="/#contact" className={styles.schoolPriceButton}>
                  お問い合わせ
                </Link>
                <Link to="/order" state={{ plan: "school" }} className={styles.schoolPriceButton}>
                  お申し込み
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-clear.png"
        />
        <div id="graphic-course" className={styles.schoolCourseWrapper}>
          <div className={styles.schoolCourseImageWrapper}>
            <StaticImage
              className={styles.schoolCourseImage}
              alt="Webデザイン・グラフィックコース"
              src="../images/blobs-graphic-course.png"
            />
          </div>
          <div className={styles.schoolCourseCard}>
            <div className={styles.schoolCourseCardHeader}>
              <h2 className={styles.schoolCourseTitle}>
                Webデザイン・{
                  breakpoints.sx || breakpoints.sm || breakpoints.md ? <br/> : null
                }グラフィックコース
              </h2>
            </div>
            <div className={styles.schoolCourseTagsGrid}>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="未経験から"
                  src="../images/icon-leaf.png"
                />
                <p className={styles.schoolCourseTagText}>未経験から</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="質問し放題"
                  src="../images/icon-fukidashi.png"
                />
                <p className={styles.schoolCourseTagText}>質問し放題</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="マンツーマン"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.schoolCourseTagText}>マンツーマン</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="お手頃料金"
                  src="../images/icon-money.png"
                />
                <p className={styles.schoolCourseTagText}>お手頃料金</p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="こんな人におすすめ"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>こんな人におすすめ</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <p className={styles.schoolCoursePointBodyText}>
                  <strong>SNSにオリジナル画像を投稿したい人、<br/>独自ブランドを立ち上げたい人</strong>
                </p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="このコースのゴール"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>このコースのゴール</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    風景・人物・食べ物などの画像補正・加工
                  </p>
                </div>
                <div className={styles.schoolCoursePointBodyRow}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    ロゴ・名刺・チラシ・パンフレット制作
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="習得できるスキル"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>習得できるスキル</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    Photoshop / Illustrator
                  </p>
                </div>
                <div className={styles.schoolCoursePointBodyRow}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    Webデザインの基礎、各種デザインソフトの使い方
                  </p>
                </div>
              </div>
            </div>
            <hr className={styles.schoolCardHr}/>
            <div className={styles.schoolCourseApps}>
              <StaticImage
                className={styles.schoolCourseAppIcon}
                alt="Photoshop"
                src="../images/icon-photoshop.png"
              />
              <StaticImage
                className={styles.schoolCourseAppIcon}
                alt="Photoshop"
                src="../images/icon-illustrator.png"
              />
            </div>
            <p className={styles.schoolCourseAppsNotice}>
              ＊Adobe社のソフト（Photoshop / Illustrator）はご自身でご契約していただきます。
            </p>
            <div className={styles.schoolPriceCard}>
              <div className={styles.schoolPricePopupWrapper}>
                <div className={styles.schoolPricePopup}>
                  <StaticImage
                    className={styles.schoolPricePopupCushion}
                    alt="思い通りのデザイン"
                    src="../images/cushion-fukidashi.png"
                  />
                  <p className={styles.schoolPricePopupText}><strong>思い通りの</strong><br/>デザイン</p>
                </div>
              </div>
              <div className={styles.schoolPriceLabel}>
                <span className={styles.schoolPriceLabelText}>料金プラン</span>
              </div>
              <p className={styles.schoolPriceText}>
                レッスン 全<span className={styles.schoolPriceBodyTextLarge}>10</span>回
                {breakpoints.xs || breakpoints.sm || breakpoints.md ? <br/> : " / "}
                <span style={breakpoints.xs || breakpoints.sm || breakpoints.md ? {fontSize: "13px"} : {fontSize: "16px"}}>週1〜2回</span>
              </p>
              {breakpoints.xs || breakpoints.sm || breakpoints.md ? <hr className={styles.schoolPriceHr}/> : null}
              <div className={styles.schoolPriceBody}>
                <p className={styles.schoolPriceBodyTitle}>4ヶ月コース</p>
                <p className={styles.schoolPriceBodyText}>月額<span className={styles.schoolPriceBodyTextLarge}>15,625</span>円</p>
                <p className={styles.schoolPriceBodyText}>1レッスンあたり: <span className={styles.schoolPriceBodyTextLarge}>6,250</span>円</p>
              </div>
              <div className={styles.schoolPriceBody}>
                <p className={styles.schoolPriceBodyTitle}>2ヶ月コース</p>
                <p className={styles.schoolPriceBodyText}>月額<span className={styles.schoolPriceBodyTextLarge}>31,250</span>円</p>
                <p className={styles.schoolPriceBodyText}>1レッスンあたり: <span className={styles.schoolPriceBodyTextLarge}>6,250</span>円</p>
              </div>
              <p className={styles.schoolPriceBodyNotice}>
                ＊期間は1ヶ月〜最大4ヶ月まで、カスタマイズ可能です。
              </p>
              <div className={styles.schoolPriceButtonRow}>
                {/* <Link to="/#contact" className={styles.schoolPriceButton}>
                  お問い合わせ
                </Link>
                <Link to="/order" state={{ plan: "school" }} className={styles.schoolPriceButton}>
                  お申し込み
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-clear.png"
        />
        <div id="quick-course" className={styles.schoolCourseWrapper}>
          <div className={styles.schoolCourseImageWrapper}>
            <StaticImage
              className={styles.schoolCourseImage}
              alt="Webデザイン・クイックコース"
              src="../images/blobs-quick-course.png"
            />
          </div>
          <div className={styles.schoolCourseCard}>
            <div className={styles.schoolCourseCardHeader}>
              <h2 className={styles.schoolCourseTitle}>
                Webデザイン・{
                  breakpoints.sx || breakpoints.sm || breakpoints.md ? <br/> : null
                }クイックコース
              </h2>
            </div>
            <div className={styles.schoolCourseTagsGrid}>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="未経験から"
                  src="../images/icon-leaf.png"
                />
                <p className={styles.schoolCourseTagText}>未経験から</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="質問し放題"
                  src="../images/icon-fukidashi.png"
                />
                <p className={styles.schoolCourseTagText}>質問し放題</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="マンツーマン"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.schoolCourseTagText}>マンツーマン</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="お手頃料金"
                  src="../images/icon-money.png"
                />
                <p className={styles.schoolCourseTagText}>お手頃料金</p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="こんな人におすすめ"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>こんな人におすすめ</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <p className={styles.schoolCoursePointBodyText}>
                  <strong>ホームページをサクっと作りたい人、独自ブランドを立ち上げたい人</strong>
                </p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="このコースのゴール"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>このコースのゴール</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    ホームページを制作し、インターネット上に公開
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="習得できるスキル"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>習得できるスキル</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    STUDIO
                  </p>
                </div>
                <div className={styles.schoolCoursePointBodyRow}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    ノーコードツール（STUDIO）を使ったホームページの作り方・運用方法
                  </p>
                </div>
              </div>
            </div>
            <hr className={styles.schoolCardHr}/>
            <div className={styles.schoolCourseApps}>
              <StaticImage
                className={styles.schoolCourseAppIcon}
                style={{width: "138px"}}
                alt="STUDIO"
                src="../images/icon-studio.png"
              />
            </div>
            <p className={styles.schoolCourseAppsNotice}>
              ＊STUDIOは無料プランと有料プランがあります。目的に合わせて選んでいただきます。
            </p>
            <div className={styles.schoolPriceCard}>
              <div className={styles.schoolPricePopupWrapper}>
                <div className={styles.schoolPricePopup}>
                  <StaticImage
                    className={styles.schoolPricePopupCushion}
                    alt="すぐに始めたいならコレ！"
                    src="../images/cushion-fukidashi.png"
                  />
                  <p className={styles.schoolPricePopupText}><strong>すぐに始めたい</strong><br/>ならコレ！</p>
                </div>
              </div>
              <div className={styles.schoolPriceLabel}>
                <span className={styles.schoolPriceLabelText}>料金プラン</span>
              </div>
              <p className={styles.schoolPriceText}>
                レッスン 全<span className={styles.schoolPriceBodyTextLarge}>4</span>回
                {breakpoints.xs || breakpoints.sm || breakpoints.md ? <br/> : " / "}
                <span style={breakpoints.xs || breakpoints.sm || breakpoints.md ? {fontSize: "13px"} : {fontSize: "16px"}}>週1〜2回</span>
              </p>
              <div className={styles.schoolPriceBody}>
                <p className={styles.schoolPriceBodyText} style={{margin: "0 10px 5px 0"}}>総額<span className={styles.schoolPriceBodyTextLarge}>24,000</span>円</p>
                <p className={styles.schoolPriceBodyText}>1レッスンあたり: <span className={styles.schoolPriceBodyTextLarge}>6,250</span>円</p>
              </div>
              {breakpoints.xs || breakpoints.sm || breakpoints.md ? <hr className={styles.schoolPriceHr} style={{margin: "-10px auto 30px"}}/> : null}
              <p className={styles.schoolPriceBodyNotice}>
                STUDIOの有料プランを選択し、独自ドメイン設定をご希望の方: +1回（<strong>6,000</strong>円） 
              </p>
              <div className={styles.schoolPriceButtonRow}>
                {/* <Link to="/#contact" className={styles.schoolPriceButton}>
                  お問い合わせ
                </Link>
                <Link to="/order" state={{ plan: "school" }} className={styles.schoolPriceButton}>
                  お申し込み
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-clear.png"
        />
        <div id="single-lesson" className={styles.schoolCourseWrapper}>
          <div className={styles.schoolCourseImageWrapper} style={breakpoints.xs || breakpoints.sm || breakpoints.md ? null : {top: "-50px"}}>
            <StaticImage
              className={styles.schoolCourseImage}
              alt="単発レッスン"
              src="../images/blobs-single-lesson.png"
            />
          </div>
          <div className={styles.schoolCourseCard}>
            <div className={styles.schoolCourseCardHeader}>
              <h2 className={styles.schoolCourseTitle}>
                単発レッスン
              </h2>
            </div>
            <div className={styles.schoolCourseTagsGrid}>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="未経験から"
                  src="../images/icon-leaf.png"
                />
                <p className={styles.schoolCourseTagText}>未経験から</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="マンツーマン"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.schoolCourseTagText}>マンツーマン</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="お手頃料金"
                  src="../images/icon-money.png"
                />
                <p className={styles.schoolCourseTagText}>お手頃料金</p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="こんな人におすすめ"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>こんな人におすすめ</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <p className={styles.schoolCoursePointBodyText}>
                  <strong>独学で分からない箇所を解決したい人、<br/>自作のデザインやコードを添削してほしい人</strong>
                </p>
              </div>
            </div>
            <div className={styles.schoolPriceCard}>
              <div className={styles.schoolPricePopupWrapper}>
                <div className={styles.schoolPricePopup}>
                  <StaticImage
                    className={styles.schoolPricePopupCushion}
                    alt="1回からOK！"
                    src="../images/cushion-fukidashi.png"
                  />
                  <p className={styles.schoolPricePopupText}><strong>1回から</strong><br/>OK！</p>
                </div>
              </div>
              <div className={styles.schoolPriceLabel}>
                <span className={styles.schoolPriceLabelText}>料金プラン</span>
              </div>
              <p className={styles.schoolPriceText}>
                1レッスン<span className={styles.schoolPriceBodyTextLarge}>6,500</span>円 / 1時間
              </p>
              {breakpoints.xs || breakpoints.sm || breakpoints.md ? <hr className={styles.schoolPriceHr} style={{margin: "-10px auto 30px"}}/> : null}
              <p className={styles.schoolPriceBodyNotice}>
                <strong>対応可能レッスン</strong><br/>
                Photoshop / Illustrator / Figma / HTML / CSS / JavaScript / Bootstrap / React / Ruby / Ruby on Rails / STUDIO / Git / デプロイ / 独自ドメイン接続 / CMS / 静的サイトジェネレーション / API開発 / Google Apps Script
              </p>
              <div className={styles.schoolPriceButtonRow}>
                {/* <Link to="/#contact" className={styles.schoolPriceButton}>
                  お問い合わせ
                </Link>
                <Link to="/order" state={{ plan: "school" }} className={styles.schoolPriceButton}>
                  お申し込み
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-clear.png"
        />
        <div id="programming-course" className={styles.schoolCourseWrapper}>
          <div className={styles.schoolCourseImageWrapper}>
            <StaticImage
              className={styles.schoolCourseImage}
              alt="Webプログラミング・マスターコース"
              src="../images/blobs-programming-course.png"
            />
          </div>
          <div className={styles.schoolCourseCard}>
            <div className={styles.schoolCourseCardHeader}>
              <h2 className={styles.schoolCourseTitle}>
                プログラミング・マスターコース
              </h2>
            </div>
            <div className={styles.schoolCourseTagsGrid}>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="未経験から"
                  src="../images/icon-leaf.png"
                />
                <p className={styles.schoolCourseTagText}>未経験から</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="質問し放題"
                  src="../images/icon-fukidashi.png"
                />
                <p className={styles.schoolCourseTagText}>質問し放題</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="マンツーマン"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.schoolCourseTagText}>マンツーマン</p>
              </div>
              <div className={styles.schoolCourseTag}>
                <StaticImage
                  className={styles.schoolCourseTagIcon}
                  alt="お手頃料金"
                  src="../images/icon-money.png"
                />
                <p className={styles.schoolCourseTagText}>お手頃料金</p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="こんな人におすすめ"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>こんな人におすすめ</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <p className={styles.schoolCoursePointBodyText}>
                  <strong>Webプログラマー/エンジニアを目指す人<br/>自作のWebアプリ/サービスを立ち上げたい人</strong>
                </p>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="このコースのゴール"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>このコースのゴール</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    就職やフリーランス案件獲得に必要なポートフォリオ制作
                  </p>
                </div>
                <div className={styles.schoolCoursePointBodyRow}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    自作のWebサイトをインターネット上に公開
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.schoolCoursePoint}>
              <div className={styles.schoolCoursePointHeader}>
                <StaticImage
                  className={styles.schoolCoursePointCushion}
                  alt="習得できるスキル"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.schoolCoursePointText}>習得できるスキル</h2>
              </div>
              <div className={styles.schoolCoursePointBody}>
                <div className={styles.schoolCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    Ruby / Java / .NET(C#, VB) / HTML / CSS / JavaScript / SQL / etc...
                  </p>
                </div>
                <div className={styles.schoolCoursePointBodyRow}>
                  <div className={styles.schoolCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.schoolCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.schoolCoursePointBodyText}>
                    フロントエンドまたはバックエンドどちらの分野を目指すかによって、受講していただく言語は異なります（お気軽にお問い合わせください）
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.schoolPriceCard}>
              <div className={styles.schoolPriceLabel}>
                <span className={styles.schoolPriceLabelText}>料金プラン</span>
              </div>
              <p className={styles.schoolPriceText}>
                ご希望の分野（フロントエンドまたはバックエンド）、進みたい方向性によって期間や料金が異なります。
              </p>
              <p className={styles.schoolPriceText}>
                まずはご希望の内容をお聞きした上で、お見積りをさせていただきます。
              </p>
              <div className={styles.schoolPriceButtonRow}>
                {/* <Link to="/#contact" className={styles.schoolPriceButton}>
                  お問い合わせ
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-moss-top.png"
        />
        <div className={styles.schoolSupport}>
          <div className={styles.schoolSupportInner}>
            <h2 className={styles.schoolSupportTitle}>私たちがレッスンします！</h2>
            <p className={styles.schoolSupportText}>
              「NEUTRAL」のクリエイティブスクールでは、共通の教材を使用し、一意的にレッスンを行うことはありません。
            </p>
            <p className={styles.schoolSupportText}>
              一人一人の<strong>やりたいことに合わせてオーダーメイドのカリキュラム</strong>を組んでおります。
            </p>
            <p className={styles.schoolSupportText}>
              ホームページを作りたい、独自ブランドのロゴを作りたい、SNSに綺麗な写真をアップしたい、趣味としてデザインを楽しみたいなど、目的は人それぞれだと思います。
            </p>
            <p className={styles.schoolSupportText}>
              みなさまの<strong>やりたいことを叶えるサポート</strong>ができたら嬉しいです。
            </p>
            <div className={styles.schoolSupportStaff}>
              <StaticImage
                className={styles.schoolSupportStaffImage}
                alt="Ami"
                src="../images/staff-ami.png"
              />
              <StaticImage
                className={styles.schoolSupportStaffImage}
                alt="Ami"
                src="../images/staff-shoei.png"
              />
            </div>
          </div>
          <Link to="/#staff" className={styles.schoolSupportStaffButton}>
            プロフィールを見る
          </Link>
        </div>
        <StaticImage
          className={styles.schoolBorderImage}
          alt="Border line"
          src="../images/curve-moss-bottom.png"
        />
        {/* <div className={styles.schoolLineWrapper}>
          <p className={styles.schoolLineText}>まずは気軽に</p>
          <a className={styles.schoolLineButton} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
            無料でLINE相談してみる
          </a>
        </div> */}
      </div>
    </Layout>
  )
}

export default School
